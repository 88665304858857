import mainLogo from "Assets/images/logo.svg";
import languageLogo1 from "Assets/images/language-logo1.svg";
import languageLogo2 from "Assets/images/language-logo2.svg";
import bannerSlide1 from "Assets/images/banner-slide1.jpg";
import bannerSlide2 from "Assets/images/banner-slide2.jpg";
import btnArrow from "Assets/images/btn-arw.svg";
import MembershipPic1 from "Assets/images/membership-pic1.jpg";
import MembershipPic2 from "Assets/images/membership-pic2.jpg";
import MembershipPic3 from "Assets/images/membership-pic3.jpg";
import MembershipPic4 from "Assets/images/membership-pic4.jpg";
import MembershipPic5 from "Assets/images/membership-pic5.jpg";
import BulletSvg from "Assets/images/bullet.svg";
import Play1 from "Assets/images/plays1.jpg";
import Play2 from "Assets/images/plays2.jpg";
import Play3 from "Assets/images/plays3.jpg";
import Play4 from "Assets/images/plays4.jpg";
import DiscoverBg from "Assets/images/discover-bg.jpg";
import Artist1 from "Assets/images/artist1.jpg";
import Artist2 from "Assets/images/artist2.jpg";
import Artist3 from "Assets/images/artist3.jpg";
import Artist4 from "Assets/images/artist4.jpg";
import EventPic from "Assets/images/event-pic.jpg";
import EventThumb1 from "Assets/images/event_thumb1.jpg";
import EventThumb2 from "Assets/images/event_thumb2.jpg";
import EventThumb3 from "Assets/images/event_thumb3.jpg";
import TestiAuthor1 from "Assets/images/testi-autor1.jpg";
import TestiAuthor2 from "Assets/images/testi-autor2.jpg";
import TestiAuthor3 from "Assets/images/testi-autor3.jpg";
import TestiAuthor4 from "Assets/images/testi-autor4.jpg";
import FooterLogoSvg from "Assets/images/ftr-logo.svg";
import FacebookSvg from "Assets/images/facebook.svg";
import InstagramSvg from "Assets/images/instagram.svg";
import TwitterSvg from "Assets/images/twitter.svg";
import YoutubeSvg from "Assets/images/youtube.png";
import CongratsIcon from "Assets/images/congrats.png";
import btnArrowSvg from "Assets/images/btn-arw.svg";

import Play_Mother from "Assets/images/La_madre.png";
import Play_Ernesto from "Assets/images/La_importancia_de_llamarse_Ernesto.png";
import Play_MaleScreams from "Assets/images/Macho_grita.png";
import Play_NotTouchMyStory from "Assets/images/No_me_toques_el_cuento.png";

import Play_Ay_Carmela from "Assets/images/Ay_Carmela.png";
import Play_Contracciones from "Assets/images/Contracciones.png";
import Play_Cuento_de_Navidad from "Assets/images/Cuento_de_Navidad.png";
import Play_Entrevista_con_mi_hija_Mari from "Assets/images/Entrevista_con_mi_hija_Mari.png";
import Play_Juguetes_otos from "Assets/images/Juguetes_otos.png";
import Play_Y_Lo_que_el_viento_se_llevó from "Assets/images/Y_Lo_que_el_viento_se_llevó.png";

import Act_Candela_Peña from "Assets/images/Candela_Peña.png";
import Act_Aitana_Sánchez_Gijón from "Assets/images/Aitana_Sánchez_Gijón.png";
import Alberto_San_Juan from "Assets/images/Alberto_San_Juan.png";
import Act_Pilar_Castro from "Assets/images/Pilar_Castro.png";
import Act_Gonzalo_Castro from "Assets/images/Gonzalo_Castro.png";
import Act_Silvia_Marsó from "Assets/images/Silvia_Marsó.png";
import Act_Fernando_Cayo from "Assets/images/Fernando_Cayo.png";
import Act_Antonia_San_Juan from "Assets/images/Antonia_San_Juan.png";
import Act_Nacho_Guerreros from "Assets/images/Nacho_Guerreros.png";
import Act_act_blank_female from "Assets/images/act_blank_female.png";
import Act_act_blank_male from "Assets/images/act_blank_male.png";

import Ai_Logo from "Assets/images/ai-logo.svg";

export const assetImages = {
  mainLogo,
  languageLogo1,
  languageLogo2,
  bannerSlide1,
  bannerSlide2,
  btnArrow,
  MembershipPic1,
  MembershipPic2,
  MembershipPic3,
  MembershipPic4,
  MembershipPic5,
  BulletSvg,
  Play1,
  Play2,
  Play3,
  Play4,
  DiscoverBg,
  Artist1,
  Artist2,
  Artist3,
  Artist4,
  EventPic,
  EventThumb1,
  EventThumb2,
  EventThumb3,
  TestiAuthor1,
  TestiAuthor2,
  TestiAuthor3,
  TestiAuthor4,
  FooterLogoSvg,
  FacebookSvg,
  InstagramSvg,
  TwitterSvg,
  YoutubeSvg,
  CongratsIcon,
  btnArrowSvg,

  Play_Mother,
  Play_Ernesto,
  Play_MaleScreams,
  Play_NotTouchMyStory,

  Play_Ay_Carmela,
  Play_Contracciones,
  Play_Cuento_de_Navidad,
  Play_Entrevista_con_mi_hija_Mari,
  Play_Juguetes_otos,
  Play_Y_Lo_que_el_viento_se_llevó,

  Act_Candela_Peña,
  Act_Aitana_Sánchez_Gijón,
  Alberto_San_Juan,
  Act_Pilar_Castro,
  Act_Gonzalo_Castro,
  Act_Silvia_Marsó,
  Act_Fernando_Cayo,
  Act_Antonia_San_Juan,
  Act_Nacho_Guerreros,
  Act_act_blank_female,
  Act_act_blank_male,

  Ai_Logo
};
