/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ecosystemSlug } from "Config/Config";

/*------ import url and method ------*/
import * as url from "Helpers/UrlHelper/UrlHelper";
import { getData } from "Utils/Gateway";
import { assetImages } from "Constants/Images";

const PriceBooking = ({ setpricePlanId }) => {
  const { t } = useTranslation();

  const [planList, setPlanList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //function for get all tags
  // const getAllPlans = async () => {
  //   try {
  //     setIsLoading(true);
  //     let requestUrl =
  //       url.API_BASE_URL +
  //       url.API_GET_ALL_PLANS +
  //       `?ecosystemslug=${ecosystemSlug}`;

  //     const response = await getData(requestUrl);

  //     console.log(response);

  //     setIsLoading(false);

  //     if (response.status) {
  //       if (response.data.length > 0) {
  //         const sortedPlansByPrice = response.data.sort(
  //           (a, b) => a.price - b.price
  //         );
  //         setPlanList(sortedPlansByPrice);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  // useEffect(() => {
  //   getAllPlans();
  // }, []);

  return (
    <section className="pricing_area pd_85 bg-ylw">
        <div className="container">
            <div className="page_hdng text-center mb-5">
                <h2 className="fw-extra-light mb-4 text-primary">{t('We have Something')} 
                <span className="fw-bold d-block">{t('for All Our Customer')}</span></h2>

                <div className="bullet_border text-center">
                    <span className="d-inline-block position-relative bg-ylw">
                      <img src={assetImages.BulletSvg} alt="" />
                    </span>
                </div>
            </div>
            <div className="pricing_outer">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing_item bg-light-ylw rounded-16 h-100 p-4">
                            <div className="pricing_top mb-4">
                                <h4 className="fs-sm mb-3 text-primary fw-bold d-flex align-items-center gap-2">Flamenco
                                    <span className="gredient-bg px-3 rounded-90 py-2 text-white">1 {t('Ticket')} <i
                                            className="material-symbols-outlined icon-fill fs-sm text-white">info</i></span>
                                </h4>
                                <h2 className="fw-bold mb-2">€20 <sub className="fs-sm">/{t("month")} </sub></h2>
                                <p className="fs-md">{t("The price is monthly but renewal/registration is done quarterly")}</p>
                            </div>
                            <div className="pricing_list mb-4">
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t("Premiere invitation")}</h4>
                                        <h5 className="text-primary fw-bold fs-sm"></h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t("Tickets will be raffled among \"Flamenco\" members")}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t("Discounts on tickets")}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">10%</h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t("Not combinable with other promotions 0 spectator day")}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t("Meetings with actors in Ambigú")}</h4>
                                        <h5 className="text-primary fw-bold fs-sm"></h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t("Invitations will be raffled among \"Flamenco\" members")}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Discounts in Ambigú')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">10%</h5>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Guided tour of the theater')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">2</h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t('One per month with a maximum capacity of 20 people, assigned in order of request.')}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Gift drink for your birthday')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">2</h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t('It can be redeemed from 5 days before to 5 days after the birthday.')}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Monthly tickets')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm"></h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t('At least 1 ticket per month for a work to be decided by the space.')}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="pricing_bttm">
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#booking_modal"
                                    className="btn btn-primary w-100"
                                  >
                                    {t("Subscribe")}
                                  </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="pricing_item bg-light-ylw rounded-16 h-100 p-4">
                            <div className="pricing_top mb-4">
                                <h4 className="fs-sm mb-3 text-primary fw-bold d-flex align-items-center gap-2">Zarzuela

                                    <span className="gredient-bg px-3 rounded-90 py-2 text-white">2 {t('Tickets')} <i
                                            className="material-symbols-outlined icon-fill fs-sm text-white">info</i></span>
                                </h4>
                                <h2 className="fw-bold mb-2">€30 <sub className="fs-sm">/{t("month")} </sub></h2>
                                <p className="fs-md">{t("The price is monthly but renewal/registration is done quarterly")}</p>
                            </div>
                            <div className="pricing_list mb-4">
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t("Premiere invitation")}</h4>
                                        <h5 className="text-primary fw-bold fs-sm"></h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">2 {t("Invitations")}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t("Discounts on tickets")}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">15%</h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t("Not combinable with other promotions 0 spectator day")}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t("Meetings with actors in Ambigú")}</h4>
                                        <h5 className="text-primary fw-bold fs-sm"></h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">2 {t("invitations guaranteed per work we release.")}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Discounts in Ambigú')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">15%</h5>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Guided tour of the theater')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">2</h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t('One per month with a maximum capacity of 20 people, assigned in order of request.')}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Gift drink for your birthday')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">2</h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t('It can be redeemed from 5 days before to 5 days after the birthday.')}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Monthly tickets')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm"></h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t('At least 2 ticket per month for a work to be decided by the space.')}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="pricing_bttm">
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#booking_modal"
                                    className="btn btn-primary w-100"
                                  >
                                    {t("Subscribe")}
                                  </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing_item bg-light-ylw rounded-16 h-100 p-4">
                            <div className="pricing_top mb-4">
                                <h4 className="fs-sm mb-3 text-primary fw-bold d-flex align-items-center gap-2">Teatro
                                    <span className="gredient-bg px-3 rounded-90 py-2 text-white">4 {t('Tickets')} <i
                                            className="material-symbols-outlined icon-fill  fs-sm text-white">info</i></span>
                                </h4>
                                <h2 className="fw-bold mb-2">€50 <sub className="fs-sm">/{t("month")} </sub></h2>
                                <p className="fs-md">{t("The price is monthly but renewal/registration is done quarterly")}</p>
                            </div>
                            <div className="pricing_list mb-4">
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t("Premiere invitation")}</h4>
                                        <h5 className="text-primary fw-bold fs-sm"></h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">4 {t("Invitations")}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t("Discounts on tickets")}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">20%</h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t("Not combinable with other promotions 0 spectator day")}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t("Meetings with actors in Ambigú")}</h4>
                                        <h5 className="text-primary fw-bold fs-sm"></h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">4 {t("invitations guaranteed per work we release.")}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Discounts in Ambigú')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">20%</h5>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Guided tour of the theater')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">2</h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t('One per month with a maximum capacity of 20 people, assigned in order of request.')}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Gift drink for your birthday')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm">2</h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t('It can be redeemed from 5 days before to 5 days after the birthday.')}</p>
                                    </div>
                                </div>
                                <div className="p_list_row light-gredient-bg p-3 rounded-4 mb-2">
                                    <div className="phead_row d-flex align-items-center justify-content-between mb-2 gap-2">
                                        <h4 className="text-primary fs-md fw-regular">{t('Monthly tickets')}</h4>
                                        <h5 className="text-primary fw-bold fs-sm"></h5>
                                    </div>
                                    <div className="price_info d-flex gap-2">
                                        <i className="material-symbols-outlined icon-fill text-light-ylw fs-sm">info</i>
                                        <p className="fs-xs text-primary">{t('At least 4 ticket per month for a work to be decided by the space.')}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="pricing_bttm">
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#booking_modal"
                                    className="btn btn-primary w-100"
                                  >
                                    {t("Subscribe")}
                                  </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
  );
};

export default PriceBooking;
