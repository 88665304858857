import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import { assetImages } from "Constants/Images";
import TopPlaySildItem from "./TopPlaySlideItem/TopPlaySildItem";

//custom arrow button for react carousel outside
const PlayButtonGroup = ({ next, previous }) => {
  return (
    <div className="inbtns plys_arw d-flex align-items-center justify-content-center gap-3 mt-5">
      <button className={previous ? "" : "disable"} onClick={previous}></button>
      <button onClick={previous} className="prev slick-arrow">
        <span className="material-symbols-outlined">arrow_back</span>
      </button>
      <button onClick={next} className="next slick-arrow">
        <span className="material-symbols-outlined">arrow_forward</span>
      </button>
    </div>
  );
};

const TopPlaysSlider = () => {
  const { t } = useTranslation();

  //array of images
  const topPlayImages = [
    {
      id: 1,
      imageUrl: assetImages.Play_Mother,
    },
    {
      id: 2,
      imageUrl: assetImages.Play_Contracciones,
    },
    {
      id: 3,
      imageUrl: assetImages.Play_Ernesto,
    },
    {
      id: 4,
      imageUrl: assetImages.Play_MaleScreams,
    },
    {
      id: 5,
      imageUrl: assetImages.Play_Y_Lo_que_el_viento_se_llevó,
    },
    {
      id: 6,
      imageUrl: assetImages.Play_Juguetes_otos,
    },
    {
      id: 7,
      imageUrl: assetImages.Play_Entrevista_con_mi_hija_Mari,
    },
    {
      id: 8,
      imageUrl: assetImages.Play_Ay_Carmela,
    },
    {
      id: 9,
      imageUrl: assetImages.Play_NotTouchMyStory,
    },
    {
      id: 10,
      imageUrl: assetImages.Play_Cuento_de_Navidad,
    },
  ];

  const sliderResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 481 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="pd-50 plays_sec bg-light-ylw overflow-hidden">
      {/* <!-- ========== Start title Section ========== --> */}
      <div className="container">
        <div className="page_hdng text-center mb-5">
          <h2 className="fw-extra-light">
            <span className="fw-bold d-block">{t("Top Plays")}</span>{" "}
          </h2>
          <div className="bullet_border text-center">
            <span className="d-inline-block position-relative">
              <img src={assetImages.BulletSvg} alt="" />
            </span>
          </div>
        </div>
      </div>
      {/* <!-- ========== End title Section ========== --> */}

      {/* <!-- ========== Start slide Section ========== --> */}
      <div className="plays_slide_outer">
        <div className="plays_slider">
          <Carousel
            infinite
            responsive={sliderResponsive}
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<PlayButtonGroup />}
          >
            {/* <!-- ========== Start images slide item card Section ========== --> */}
            {topPlayImages.map((slideItem, index) => {
              return <TopPlaySildItem key={index} slideItem={slideItem} />;
            })}
            {/* <!-- ========== End images slide item card Section ========== --> */}
          </Carousel>
        </div>
      </div>
      {/* <!-- ========== End slide Section ========== --> */}
    </section>
  );
};

export default TopPlaysSlider;
