import Authmiddleware from "MiddleWare/AuthMiddleWare";
import GlobalContext from "./Context/GlobalContext";

const App = () => {
  return (
    <GlobalContext>
      <Authmiddleware />
    </GlobalContext>
  );
};

export default App;
