import { Link } from "react-router-dom";
import { assetImages } from "Constants/Images";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer_sec bg-ylw pd-50">
      <div className="container position-relative mb-2">
        {/* <!-- ========== Start Footer link Section ========== --> */}
        <div className="ftr_outer">
          <div className="row">
            {/* <!-- ========== Start footer logo Section ========== --> */}
            <div className="col-lg-3">
              <div className="ftr_logo mb-5">
                <Link to="/" className="ftr_logo d-inline-block">
                  <img src={assetImages.FooterLogoSvg} alt="" />
                </Link>
              </div>
            
            </div>
            {/* <!-- ========== End footer logo Section ========== --> */}

            {/* <!-- ========== Start main links Section ========== --> */}
            <div className="col-lg-3 col-md-4 col-6">
              <div className="ftr_item">
                <h3 className="fs-md fw-bold mb-4">
                  {t("Main Links")}
                </h3>
                <ul className="list_style_none ftr_links">
                  <li className="mb-2">
                    <a href="https://elpavonteatro.es/teatro-pavon/" 
                    className="fs-sm text-primary fw-medium" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    >
                      {t("Pavón Theater")}
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="https://elpavonteatro.es/programacion-teatral/" 
                    className="fs-sm text-primary fw-medium" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    >
                      {t("Programming")}
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="https://elpavonteatro.es/informacion-al-espectador/" 
                    className="fs-sm text-primary fw-medium" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    >
                      {t("Information for the spectator")}
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="https://entradas.elpavonteatro.es/elpavonteatro/es_ES/tickets" 
                    className="fs-sm text-primary fw-medium" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    >
                      {t("Tickets")}
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="https://elpavonteatro.es/contacto/" 
                    className="fs-sm text-primary fw-medium" 
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                      {t("Contact")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- ========== End main links Section ========== --> */}

            {/* <!-- ========== Start contact now Section ========== --> */}
            <div className="col-lg-3 col-md-4 col-6">
              <div className="ftr_item">
                <h3 className="fs-md fw-bold mb-4">
                  {t("Contact Info")}
                </h3>
                <div className="contact_info_outer">
                  <div className="contact_info_row d-flex gap-2 mb-3">
                    <span className="material-symbols-outlined icon-fill info-icon fs-lg">
                      location_on
                    </span>
                    <p className="fw-medium">Calle de Embajadores, 9, 28012, Madrid, España</p>
                  </div>
                  <div className="contact_info_row d-flex gap-2 mb-3">
                    <span className="material-symbols-outlined icon-fill info-icon fs-lg">
                      mail
                    </span>
                    <p className="fw-medium">
                      <Link
                        to="#"
                        className="fs-sm text-primary d-inline-block"
                      >
                        taquilla@elpavonteatro.es
                      </Link>
                    </p>
                  </div>
                  <div className="contact_info_row d-flex gap-2">
                    <span className="material-symbols-outlined icon-fill info-icon fs-lg">
                      phone_in_talk
                    </span>
                    <p className="fw-medium">
                      <span className="d-block mb-2 text-primary">
                        <Link to="tel:914 56 75 13" className="text-primary">
                          914 56 75 13
                        </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- ========== End contact now Section ========== --> */}

            {/* <!-- ========== Start social media Section ========== --> */}
            <div className="col-lg-3 col-md-4 col-12">
              <div className="ftr_links_outer">
                <div className="ftr_item mb-5">
                  <h3 className="fs-md fw-bold mb-4">{t("Social Media")}</h3>
                  <ul className="list_style_none ftr_links">
                    <li className="mb-2">
                      <a
                        href="https://www.youtube.com/@elPavonTeatro"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fs-sm text-primary fw-medium d-flex align-items-center gap-2"
                      >
                        <i className="d-inline-block">
                          <img src={assetImages.YoutubeSvg} alt="" />
                        </i>
                        <span className="d-inline-block">Youtube</span>
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        href="https://www.facebook.com/ElPavonTeatro/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fs-sm text-primary fw-medium d-flex align-items-center gap-2"
                      >
                        <i className="d-inline-block">
                          <img src={assetImages.FacebookSvg} alt="" />
                        </i>
                        <span className="d-inline-block">Facebook</span>
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        href="https://www.instagram.com/elpavonteatro/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fs-sm text-primary fw-medium d-flex align-items-center gap-2"
                      >
                        <i className="d-inline-block">
                          <img src={assetImages.InstagramSvg} alt="" />
                        </i>
                        <span className="d-inline-block">Instagram</span>
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        href="https://twitter.com/ElPavonTeatro"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fs-sm text-primary fw-medium d-flex align-items-center gap-2"
                      >
                        <i className="d-inline-block">
                          <img src={assetImages.TwitterSvg} alt="" />
                        </i>
                        <span className="d-inline-block">Twitter</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- ========== End social media Section ========== --> */}
          </div>
        </div>
        {/* <!-- ========== End Footer link Section ========== --> */}

       
      </div>

      <div className="container position-relative ">
       {/* <!-- ========== Start copyright Section ========== --> */}
        <div className="">
          <span className="me-5 fw-bold">{t("Copyright")} © 2024 Teatro Pavón</span>

          <span className="me-4">
            <a href="https://elpavonteatro.es/nota-legal/" 
            className="fs-sm text-primary fw-medium" 
            target="_blank" 
            rel="noopener noreferrer"
            >
              {t("Nota legal")}
            </a>
          </span>

          <span className="me-4">
          
            <a href="https://elpavonteatro.es/politica-de-privacidad-y-datos-personales/" 
            className="fs-sm text-primary fw-medium" 
            target="_blank" 
            rel="noopener noreferrer"
            >
              {t("Política de privacidad y datos personales")}
            </a>
     
          </span>
          <span className="me-4">
      
            <a href="https://elpavonteatro.es/politica-de-cookies/" 
            className="fs-sm text-primary fw-medium" 
            target="_blank" 
            rel="noopener noreferrer"
            >
              {t("Política de cookies ")}
            </a>
     
          </span>
          <span className="">
       
            <a href="https://elpavonteatro.es/condiciones-de-uso-y-contratacion/" 
            className="fs-sm text-primary fw-medium" 
            target="_blank" 
            rel="noopener noreferrer"
            >
              {t("Condiciones de uso y contratación")}
            </a>
          </span>
        </div>
        {/* <!-- ========== End copyright Section ========== --> */}
      </div>
    </footer>
  );
};

export default Footer;
