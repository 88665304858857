import { Link } from "react-router-dom";
import { assetImages } from "Constants/Images";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { i18n } = useTranslation();

  return (
    <header className="header_outer">
      <div className="container">
        <div className="hdr_inr d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to="/" className="d-block">
              <img src={assetImages.mainLogo} alt="" />
            </Link>
          </div>
          <div className="language_list d-flex align-items-center gap-2">
            <Link
              to="#"
              className="d-flex align-items-center gap-1 fw-bold text-white fs-sm"
              onClick={() => {
                i18n.changeLanguage("en");
              }}
            >
              <img src={assetImages.languageLogo1} alt="" />
              <span>EN</span>
            </Link>
            <Link
              to="#"
              className="d-flex align-items-center gap-1 fw-bold text-white fs-sm"
              onClick={() => {
                i18n.changeLanguage("es");
              }}
            >
              <img src={assetImages.languageLogo2} alt="" />
              <span>ES</span>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
