import { assetImages } from "Constants/Images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BookingSuccessModal = () => {
  const { t } = useTranslation();

  return (
    <div className="booking_modal">
      <div
        className="modal fade show"
        data-bs-backdrop="static"
        id="bookin_success_modal"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content p-0 rounded-16 overflow-hidden">
            <div className="modal-body p-5">
              <div className="success_pic text-center mb-4">
                <img src={assetImages.CongratsIcon} alt="" />
              </div>
              <div className="success_text text-center">
                <h3 className="fw-bold text-primary mb-2">
                  {t("Booking Successful")}
                </h3>
                <p className="fs-md mb-3 text-primary">
                  {t(
                    "We look forward to seeing you at the event. Enjoy the show!"
                  )}
                  .
                </p>
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  {t("Done")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSuccessModal;
