/* eslint-disable */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "Routes/Route";

const Authmiddleware = () => {
  const fullURL = window.location.href;

  const parsedURL = new URL(fullURL);
  const searchParams = parsedURL.searchParams;

  const refParam = searchParams.get("ref");
  const secretParam = searchParams.get("secret");

  let redirectUrl = "";
  let pathWithoutDomain = "";

  if (refParam && secretParam) {
    redirectUrl = new URL(refParam);
    pathWithoutDomain = redirectUrl.pathname;
  }

  let token = null;

  token = localStorage.getItem("token");

  return (
    <Switch>
      {/* ====== mapping of non authenticated routes start ====== */}

      {!token &&
        publicRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}

      {/* ====== mapping of non authenticated routes end ====== */}

      {/* ====== mapping of authenticated route start ====== */}

      {token &&
        protectedRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}

      {/* ====== mapping of authenticated route end ====== */}

      {/* ====== default redirect to dashboard ======= */}
      {/* {token ? <Redirect to="/feed" /> : <Redirect to="/" />} */}
      {token ? (
        refParam && secretParam ? (
          <Redirect to={`${pathWithoutDomain}?secret=${secretParam}`} />
        ) : (
          <Redirect to="/feed" />
        )
      ) : (
        <Redirect to="/" />
      )}
    </Switch>
  );
};

export default Authmiddleware;
