import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { assetImages } from "Constants/Images";

const SildeItemCard = ({ slideItem, setSourceName }) => {
  const { t } = useTranslation();

  return (
    <div
      className="banner_slide_item bg-cover"
      style={{ backgroundImage: `url(${slideItem.backgroundImage})` }}
    >
      <div className="banner_content">
        <div className="container">
          <div className="bnr_text">
            <h2 className="fw-thin text-white mb-3">
              {t("Welcome to the")}{" "}
              <span className="fw-bold">{t("Pavón Theater Members")}</span>{" "}
              {t("Club")}!
            </h2>
            <p className="text-white fs-lg mb-3">
              {t("Join our exclusive community of theater lovers")}
            </p>
            <div className="arws_btn position-relative d-inline-block">
              <Link
                to="#"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#booking_modal"
                onClick={() => {
                  setSourceName("theater_member_club	");
                }}
              >
                {t("Book your seats now")}
              </Link>
              <span className="indigate_arw d-inline-block">
                <img src={assetImages.btnArrow} alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SildeItemCard;
