import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { assetImages } from "Constants/Images";
import EventCard from "./EventCard/EventCard";

const UpcomingEvents = ({ setSourceName }) => {
  const { t } = useTranslation();

  //Array of upcoming event list.This will replace by API.
  const upcomingEventList = [
    {
      id: 1,
      eventThumb: assetImages.Play_Mother,
      eventTitle: "LA MADRE",
      eventTime: "6 Mar al 12 May 2024",
    },
    {
      id: 2,
      eventThumb: assetImages.Play_Ernesto,
      eventTitle: "LA IMPORTANCIA DE LLAMARSE ERNESTO",
      eventTime: "15 May al 30 Jun 2024",
    },
    {
      id: 3,
      eventThumb: assetImages.Play_MaleScreams,
      eventTitle: "MACHO GRITA",
      eventTime: "¡Últimas funciones! Hasta el 17 Jun 2024",
    },
    {
      id: 3,
      eventThumb: assetImages.Play_NotTouchMyStory,
      eventTitle: "NO ME TOQUES EL CUENTO",
      eventTime: "Hasta 26 Jul 2024",
    },
  ];

  return (
    <section className="event_sec" style={{backgroundImage: `url(${assetImages.EventPic})`}}>
      <div className="container">
        <div className="row align-items-center">
      
          {/* <!-- ========== Start upcoming event and booking Section ========== --> */}
          <div className="col-lg-6 offset-lg-6">
            <div className="evnt_rightpnl">
              {/* <!-- ========== Start upcoming event heading Section ========== --> */}
              <div className="page_hdng mb-3">
                <h5 className="fs-lg text-white mb-1 text-uppercase">
                  {t("Upcoming Events")}
                </h5>
                <h2 className="fw-extra-light text-white">
                  <span className="fw-bold d-block">
                    {t("This Week's Events")}
                  </span>
                </h2>
              </div>
              {/* <!-- ========== End upcoming event heading Section ========== --> */}

              {/* <!-- ========== Start upcoming event list Section ========== --> */}
              <div className="event_outer">
                {upcomingEventList.map((eventData, index) => {
                  return <EventCard key={index} cardItem={eventData} />;
                })}
              </div>
              {/* <!-- ========== End upcoming event list Section ========== --> */}

              {/* <!-- ========== Start booking button Section ========== --> */}
              <div className="book_btn mt-5">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#booking_modal"
                  onClick={() => {
                    setSourceName("book_upcoming_events	");
                  }}
                >
                  {t("Book Now For The Events")}
                </Link>
              </div>
              {/* <!-- ========== End booking button Section ========== --> */}
            </div>
          </div>
          {/* <!-- ========== End upcoming event and booking Section ========== --> */}
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvents;
