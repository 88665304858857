import Home from "Pages/Home/Home";

/*======= public routes =======*/
const publicRoutes = [
  { path: "/", component: Home }, // home page
];
/*======= protected routes =======*/
const protectedRoutes = [];

export { publicRoutes, protectedRoutes };
