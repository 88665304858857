import React from "react";

const FaqCard = ({ faqData, index }) => {
  return (
    <div className="accordion-item bg-white ">
      <h2 className="accordion-header">
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#flush-collapse${index}`}
          aria-expanded="true"
        >
          <span>{faqData.question}?</span>
          <i className="material-symbols-outlined">expand_more</i>
        </button>
      </h2>
      <div
        id={`flush-collapse${index}`}
        className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
        data-bs-parent="#faq-accordian"
        style={{}}
      >
        <div className="accordion-body">
          <div className="accor_para">
            <p className="fs-md">{faqData.answer}.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqCard;
