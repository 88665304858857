import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Header from "Components/Common/Header/Header";
import Banner from "Components/Home/Banner/Banner";
import BookingContact from "Components/Home/BookingContact/BookingContact";
import CustomerSatisfaction from "Components/Home/CustomerSatisfaction/CustomerSatisfaction";
import ExclusiveModule from "Components/Home/ExclusiveModule/ExclusiveModule";
import Faq from "Components/Home/Faq/Faq";
import MemberShipBenefits from "Components/Home/MembershipBenefits/MemberShipBenefits";
import PriceBooking from "Components/Home/PriceBooking/PriceBooking";
import Testimonials from "Components/Home/Testimonials/Testimonials";
import TheaterPrivileges from "Components/Home/TheaterPrivileges/TheaterPrivileges";
import TopArtistSlider from "Components/Home/TopArtistSlider/TopArtistSlider";
import TopPlaysSlider from "Components/Home/TopPlaysSlider/TopPlaysSlider";
import UpcomingEvents from "Components/Home/UpcomingEvents/UpcomingEvents";
import Footer from "Components/Common/Footer/Footer";
import BookingModal from "Components/Common/Modals/BookingModal";
import BookingSuccessModal from "Components/Common/Modals/BookingSuccessModal";
import SubscribePaymentModal from "Components/Common/Modals/SubscribePaymentModal";
import AiChat from "Components/Home/AiChat/AiChat";

const Home = () => {
  const { i18n } = useTranslation(); //translation requirments

  const [sourceName, setSourceName] = useState("");
  const [pricePlanId, setpricePlanId] = useState(null);

  //set the default language is "es"
  useEffect(() => {
    i18n.changeLanguage("es");
  }, [i18n]);

  return (
    <>
      <Header />
      <Banner setSourceName={setSourceName} />
      <MemberShipBenefits setSourceName={setSourceName} />
      <TopPlaysSlider />
      <TheaterPrivileges setSourceName={setSourceName} />
      <TopArtistSlider />
      <ExclusiveModule />
      <UpcomingEvents setSourceName={setSourceName} />
      {/* <Testimonials /> */}
      <CustomerSatisfaction />
      <PriceBooking setpricePlanId={setpricePlanId} />
      <Faq />
      <BookingContact setSourceName={setSourceName} />
      <Footer />

      <BookingModal sourceName={sourceName} setSourceName={setSourceName} />
      <BookingSuccessModal />
      <SubscribePaymentModal
        pricePlanId={pricePlanId}
        setpricePlanId={setpricePlanId}
      />
      
      <AiChat />

      
    </>
  );
};

export default Home;
