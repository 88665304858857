import { Link } from "react-router-dom";

const BenifitCardItem = ({ t, benefitItem, setSourceName }) => {
  return (
    <div className={`${benefitItem.colClassName} col-sm-6 mb-3`}>
      <div
        className="benefits_item bg-cover rounded-10 overflow-hidden p-5 position-relative d-flex align-items-end"
        style={{
          backgroundImage: `url(${benefitItem.backgroundImage})`,
        }}
      >
        <div className="membership_text">
          <h3 className={`${benefitItem.textClass} mb-2 fw-bold`}>
            {benefitItem.title}
          </h3>
          <p className={`${benefitItem.descriptionClass} fs-md mb-3`}>
            {benefitItem.description}
          </p>
          {benefitItem.showBookingButton ? (
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#booking_modal"
              className="btn btn-primary"
              onClick={() => {
                setSourceName("membership_benefit");
              }}
            >
              {t("Book your seats now")}
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BenifitCardItem;
