/* eslint-disable */
import { useState, createContext, useEffect } from "react";

export const GlobalProvider = createContext();

const GlobalContext = (props) => {
  let screenHeight = window.innerHeight;

  return (
    <GlobalProvider.Provider
      value={{
        /*======= screen height =================*/
        screenHeight,
      }}
    >
      {props.children}
    </GlobalProvider.Provider>
  );
};

export default GlobalContext;
