import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomerSatisfactionCard from "./CustomerSatisfactionCard/CustomerSatisfactionCard";
import { assetImages } from "Constants/Images";

const CustomerSatisfaction = () => {
  const { t } = useTranslation();

  //Array of customer staisfactions
  const customerSatisFactionList = [
    {
      id: 1,
      className: "col-lg-6 mb-2",
      title: t("Invitations"),
      icon: "linked_services",
      description: t(
        "Up to 4 tickets for the main premieres of our programming and up to 4 monthly tickets"
      ),
    },
    {
      id: 2,
      className: "col-lg-6 mb-2",
      title: t("Unique Experiences"),
      icon: "star_half",
      description: t(
        "Only for members, exclusive meetings with the artists of our main works."
      ),
    },
    {
      id: 3,
      className: "col-lg-6 mb-2",
      title: t("We Celebrate Your Birthday"),
      icon: "cake",
      description: t("we invite you to two drinks at our Ambigú."),
    },
    {
      id: 4,
      className: "col-lg-6 mb-2",
      title: t("Exclusive Activities"),
      icon: "campaign",
      description: t(
        "Discover the history and ins and outs of Pavón on our exclusive visits for members."
      ),
    },
    {
      id: 5,
      className: "col-lg-12",
      title: t("Discounts and Offers"),
      icon: "sell",
      description: t(
        "Up to 20% discount on the base price of tickets and drinks at our Ambigú. Plus, access to exclusive offers."
      ),
    },
  ];

  return (
    <section className="customer_satisfication_sec pd_85 bg-light-ylw">
      <div className="container">
        {/* <!-- ========== Start heading Section ========== --> */}
        <div className="page_hdng text-center mb-5">
          <h2 class="fw-extra-light text-primary">
            {t("Why become a")}{" "}
            <span class="fw-bold d-inline-block">{t("Member")}</span>
            <br /> {t("of the")}{" "}
            <span class="fw-bold d-inline-block">
              {t("Pavón Members Club")}?
            </span>
          </h2>

          <div className="bullet_border text-center">
            <span className="d-inline-block position-relative">
              <img src={assetImages.BulletSvg} alt="" />
            </span>
          </div>
        </div>
        {/* <!-- ========== End heading Section ========== --> */}

        {/* <!-- ========== Start customer satisfaction Section ========== --> */}
        <div className="customer_pnl">
          <div className="row">
            {customerSatisFactionList.map((item, index) => {
              return <CustomerSatisfactionCard key={index} item={item} />;
            })}
          </div>
        </div>
        {/* <!-- ========== End customer satisfaction Section ========== --> */}

        {/* <!-- ========== Start load more button Section ========== --> */}
        <div className="load_more_btn mt-5 d-flex justify-content-center">
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#booking_modal"
            className="btn btn-primary"
            style={{paddingLeft: '10%', paddingRight: '10%' }}
          >
            {t("Subscribe")}
          </Link>
        </div>
        {/* <!-- ========== End load more button Section ========== --> */}
      </div>
    </section>
  );
};

export default CustomerSatisfaction;
