import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FaqCard from "./FaqCard/FaqCard";

const Faq = () => {
  const { t } = useTranslation();

  //Array of FAQ
  const faqList = [
    {
      id: 1,
      question: t("What does the monthly subscription include"),
      answer: t(
        "Our monthly subscription grants you access to a variety of live theater performances, exclusive events, and special discounts on tickets"
      ),
    },
    {
      id: 2,
      question: t("Can I cancel my subscription at any time"),
      answer: t("You can request the cancellation of your membership before the end of the subscription quarter, then the renewal will be automatic"),
    },
   
    {
      id: 4,
      question: t("Are there any additional fees for using the subscription?"),
      answer: t("No, your membership entitles you to everything detailed above and many more surprises that we will reveal little by little"),
    },
    {
      id: 5,
      question: t("Can I upgrade or downgrade my subscription plan?"),
      answer: t("You can upgrade your membership before the end of the subscription quarter, otherwise renewal will be automatic."),
    },
  
  ];

  return (
    <section className="faq_sec bg-white pd_85">
      <div className="container">
        <div className="faq_outer">
          <div className="row">
            {/* <!-- ========== Start Faq heading Section ========== --> */}
            <div className="col-lg-3">
              <div className="faq_hdng_pnl">
                <div className="page_hdng pb-3 mb-4 position-relative">
                  <h2 className="fw-extra-light">
                    {t("Frequently Asked")}
                    <span className="d-block fw-bold">{t("Question")}</span>
                  </h2>
                </div>
                <p className="mb-3 fs-md">{t("For any query ask as now")}</p>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#booking_modal"
                  className="btn btn-primary"
                >
                  {t("Subscribe")}
                </Link>
              </div>
            </div>
            {/* <!-- ========== End Faq heading Section ========== --> */}

            {/* <!-- ========== Start faq list Section ========== --> */}
            <div className="col-lg-9">
              <div className="accordian_area">
                <div className="accordion accordion-flush" id="faq-accordian">
                  {/* <!-- ========== Start faq list map Section ========== --> */}
                  {faqList.map((faqData, index) => {
                    return (
                      <FaqCard key={index} index={index} faqData={faqData} />
                    );
                  })}
                  {/* <!-- ========== End faq list map Section ========== --> */}
                </div>
              </div>
            </div>
            {/* <!-- ========== End faq list Section ========== --> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
