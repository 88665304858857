/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "Helpers/UrlHelper/UrlHelper";
import { getData } from "Utils/Gateway";
import { useTranslation } from "react-i18next";

const SubscribePaymentModal = ({ setpricePlanId, pricePlanId }) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [pricing, setPricing] = useState(1);
  const [duration, setDuration] = useState(30);
  const [noOfPersonsAllowed, setnoOfPersonsAllowed] = useState(0);
  const [kpis, setKpis] = useState([]);

  //get details
  const getPlanDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_PLAN_DETAILS + `/${pricePlanId}`;

      const response = await getData(requestUrl);

      //console.log('details response', response);

      if (response.status) {
        setTitle(response.data.title);
        setDescription(response.data.description);
        setPricing(response.data.price);
        setDuration(response.data.duration);
        setnoOfPersonsAllowed(response.data.persons);
        setKpis(response.data.kpis);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const makePayment = async () => {
    console.log("make payment");

    let successUrl = url.FRONTEND_BASE_URL + "/payment/success";
    let failureUrl = url.FRONTEND_BASE_URL + "/payment/failure";

    if (invoiceId) {
      successUrl = successUrl + `?ref=${invoiceId}`;
    }

    console.log("userinfo._id", userInfo._id);

    const stripe = await loadStripe(
      "pk_test_51IJm7YL6PquBzXKuvGXoEkT32UbYTvpafT4Wf6naaoNezsrD9Lt1juEzQd4SJzJWscPci5jr2LRbUcUxu5wUu2Pn00kTokCrRA"
    );

    let requestUrl =
      url.API_BASE_URL + url.API_CART_CHECKOUT_SESSION + `?token=${token}`;

    const paymentInfo = {
      cartItems: stripeProductItems,
      userId: userInfo._id,
      invoiceid: invoiceId,
      successurl: successUrl,
      failureurl: failureUrl,
    };

    try {
      const response = await postData(requestUrl, paymentInfo);

      console.log("response", response);

      if (response.id) {
        const result = stripe.redirectToCheckout({
          sessionId: response.id,
        });
      }
    } catch (error) {
      console.log("error message", error);
    }
  };

  useEffect(() => {
    if (pricePlanId) {
      getPlanDetails();
    }
  }, [pricePlanId]);

  return (
    <div className="booking_modal subscribe_modal">
      <div
        className="modal fade show"
        data-bs-backdrop="static"
        id="subscribe_modal"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content p-0 rounded-16 overflow-hidden">
            <div className="modal-header p-0">
              {/* <button
                type="button"
                className="close d-flex align-items-center justify-content-center rounded-circle gredient-bg text-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-symbols-outlined">close</i>
              </button> */}
            </div>
            <div className="modal-body p-0">
              <div className="booking_outer d-flex">
                <div className="left_subscribe w-50">
                  <div className="pricing_item bg-light-ylw h-100 p-4">
                    <div className="pricing_top mb-4">
                      <h4 className="fs-sm mb-3 text-primary fw-bold d-flex align-items-center gap-2">
                        {title}
                        <span className="gredient-bg px-3 rounded-90 py-2">
                          {noOfPersonsAllowed} {t("Person")}
                        </span>
                      </h4>
                    </div>
                    <div className="pricing_list mb-4">
                      <h4 className="text-uppercase mb-4 text-uppercase fs-sm fw-bold">
                        {t("What's included")} :
                      </h4>
                      <ul className="list_style_none">
                        {kpis.map((kpi, index) => {
                          return (
                            <li className="d-flex gap-2 mb-3" key={index}>
                              <span className="material-symbols-outlined icon-fill text-blue fs-lg">
                                check_circle
                              </span>
                              <p>{kpi}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="right_subscribe d-flex align-items-center flex-wrap w-50 p-4">
                  <div className="payment_hdng mb-4 w-100 text-center mt-5">
                    <h2 className="fs-xl fw-bold">
                      {t("Please check all details")} <br />{" "}
                      {t("carefully before payment")}
                    </h2>
                  </div>
                  <div className="payment_amount mb-4 w-100 text-center">
                    <h5 className="mb-3 text-primary fs-sm fw-bold">
                      {t("Billed")}
                    </h5>
                    <h2 className="fw-bold">€{pricing}</h2>
                    <p className="fs-md text-primary">
                      {t("included package price and all taxes")}
                    </p>
                  </div>

                  <div className="signup_btns_outer d-flex align-items-center justify-content-between mt-4 w-100">
                    <Link
                      to="#"
                      className="btn close_btn"
                      //     data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      //     data-bs-target="#error_modal"
                      onClick={() => {
                        setpricePlanId(null);
                      }}
                    >
                      {t("Cancel")}
                    </Link>
                    <button
                      //     data-bs-dismiss="modal"
                      //     data-bs-toggle="modal"
                      //     data-bs-target="#success_modal"
                      className="btn btn-primary"
                      onClick={() => {
                        alert("Pending Devlopement");
                      }}
                    >
                      {t("Confirm Payment")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribePaymentModal;
