import { Link } from "react-router-dom";

const EventCard = ({ cardItem }) => {
  return (
    <div className="event_row d-flex gap-3 align-items-start py-3">
      {/* <!-- ========== Start event logo Section ========== --> */}
      <Link to="#" className="evnt_pic overflow-hidden ">
        <img
          className="h-100 w-100 object-fit-cover"
          src={cardItem.eventThumb}
          alt=""
        />
      </Link>
      {/* <!-- ========== End event logo Section ========== --> */}

      {/* <!-- ========== Start event title and time Section ========== --> */}
      <div className="event_text">
        {/* <!-- ========== Start event title Section ========== --> */}
        <Link
          to="#"
          className="pb-2 mb-2 position-relative text-white fs-xl text-uppercase fw-bold"
        >
          {cardItem.eventTitle}
        </Link>
        {/* <!-- ========== End event title Section ========== --> */}

        {/* <!-- ========== Start event time Section ========== --> */}
        <h5 className="fs-sm text-white">{cardItem.eventTime}</h5>
        {/* <!-- ========== End event time Section ========== --> */}
      </div>
      {/* <!-- ========== End event title and time Section ========== --> */}
    </div>
  );
};

export default EventCard;
