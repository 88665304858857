import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import { assetImages } from "Constants/Images";
import ArtistSlideCard from "./ArtistSlideCard/ArtistSlideCard";

//custom arrow button for react carousel outside
const ArtistButtonGroup = ({ next, previous }) => {
  return (
    <div className="inbtns plys_arw d-flex align-items-center justify-content-center gap-3 mt-5">
      <button className={previous ? "" : "disable"} onClick={previous}></button>
      <button onClick={previous} className="prev slick-arrow">
        <span className="material-symbols-outlined">arrow_back</span>
      </button>
      <button onClick={next} className="next slick-arrow">
        <span className="material-symbols-outlined">arrow_forward</span>
      </button>
    </div>
  );
};

const TopArtistSlider = () => {
  const { t } = useTranslation();

  //top artist array.It will replace by API
  const topArtistArray = [
    {
      id: 1,
      imageUrl: assetImages.Act_Candela_Peña,
      artistName: "Candela Peña",
      role: "",
    },
    {
      id: 2,
      imageUrl: assetImages.Act_Aitana_Sánchez_Gijón,
      artistName: "Aitana Sánchez-Gijón",
      role: "",
    },
    {
      id: 3,
      imageUrl: assetImages.Alberto_San_Juan,
      artistName: "Alberto San Juan",
      role: "",
    },
    {
      id: 4,
      imageUrl: assetImages.Act_Pilar_Castro,
      artistName: "Pilar Castro",
      role: "",
    },
    {
      id: 5,
      imageUrl: assetImages.Act_Gonzalo_Castro,
      artistName: "Gonzalo Castro",
      role: "",
    },
    {
      id: 6,
      imageUrl: assetImages.Act_Silvia_Marsó,
      artistName: "Silvia Marsó",
      role: "",
    },
    {
      id: 7,
      imageUrl: assetImages.Act_Fernando_Cayo,
      artistName: "Fernando Cayo",
      role: "",
    },
    {
      id: 8,
      imageUrl: assetImages.Act_Antonia_San_Juan,
      artistName: "Antonia San Juan",
      role: "",
    },
    {
      id: 9,
      imageUrl: assetImages.Act_Nacho_Guerreros,
      artistName: "Nacho Guerreros",
      role: "",
    },
  ];

  //react carousel configuration.
  const sliderResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },

    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="pd-50 artist_sec bg-light-ylw overflow-hidden">
      {/* <!-- ========== Start top artist heading Section ========== --> */}
      <div className="container">
        <div className="page_hdng text-center mb-5">
          <h2 className="fw-extra-light">
            <span className="fw-bold d-block">
              {t("Actors in Our Programming")}
            </span>{" "}
          </h2>
          <div className="bullet_border text-center">
            <span className="d-inline-block position-relative">
              <img src={assetImages.BulletSvg} alt="" />
            </span>
          </div>
        </div>
      </div>
      {/* <!-- ========== End top artist heading Section ========== --> */}

      {/* <!-- ========== Start artist slide Section ========== --> */}
      <div className="plays_slide_outer">
        <div className="artist_slider">
          <Carousel
            infinite
            responsive={sliderResponsive}
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<ArtistButtonGroup />}
          >
            {topArtistArray.map((slideItem, index) => {
              return <ArtistSlideCard key={index} slideItem={slideItem} />;
            })}
          </Carousel>
        </div>
      </div>
      {/* <!-- ========== End artist slide Section ========== --> */}
    </section>
  );
};

export default TopArtistSlider;
