import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helpers/UrlHelper/UrlHelper";
import { getData, postData } from "Utils/Gateway";

import { ecosystemSlug, owneremail } from "Config/Config";

//------ get utils functions -----------
import { GetDateTime } from "Utils/DateTimeFromTimestamp";

import { assetImages } from "Constants/Images";

const AiChat = () => {
        // translation
        const { t } = useTranslation();

        // all chat messages in array to display
        const [chatMessages, setChatMessages] = useState([]);

        const [gptPrompts, setGptPrompts] = useState([]);

        // current chat query asked by logged in user
        const [queryMessage, setQueryMessage] = useState("");

        const [waitingResponse, setWaitingResponse] = useState(false);

        const [aiCommunicationError, setAiCommunicationError] = useState(false);

        const [gptThreadId, setGptThreadId] = useState(null);

        const [gptAssistantId, setGptAssistantId] = useState(null);

        const [chatObjective, setChatObjective] = useState(null); // 

        const userInfo = JSON.parse(localStorage.getItem("userInfo"));

        // get CurrentTime and Location
        const currentTime = new Date();
        const formattedTime = GetDateTime(currentTime);

        // location info
        let locationInfo = null;
        if (localStorage.getItem("locationInfo")) {
            locationInfo = JSON.parse(localStorage.getItem("locationInfo"));
        }
        const token = localStorage.getItem("token");

        // reference chat object to hold information about chat data
        let chatMessageObj = {
            userid: null,
            username: "",
            userimage: "",
            tracker: "0",
            time: GetDateTime(new Date()),
            suggest: true,
            tags: [],
            message: "",
            moduleselected: "",
        };

        // send query to chatGpt and get response
        const chatMessageHandler = async (e) => {

                    if (e) e.preventDefault(); // check if e is provided to prevent errors when called from FORM

                    setWaitingResponse(true);

                    // push the message of user as question in ChatMessage to display in view
                    // copy all default properties
                    let queryMessageObj = { ...chatMessageObj };
                    queryMessageObj.userid = '123'//localStorage.getItem("userID");
                    queryMessageObj.message = queryMessage;

                    if(queryMessage.length > 1){
                    setChatMessages((prevResponses) => [...prevResponses, queryMessageObj]);
                    }    

                    // reset values of properties
                    resetAll();

                    // gpt post params
                    const userName = userInfo?userInfo.name:'Guest';

                    const gptParams = {
                    threadid: gptThreadId,
                    assistantid: gptAssistantId,
                    username : userName,
                    message: queryMessage,
                    promotslug : chatObjective
                    }

                    try {

                    let requestURL = url.API_BASE_URL + url.API_CHAT_GPT_ASSISTANT;

                    console.log("request url -->", requestURL);
                    console.log("request params -->", gptParams);

                    //const response = {}
                    const response = await postData(requestURL, gptParams);
                    
                    console.log("response -->", response);

                    if (response.status) {
                        setWaitingResponse(false);

                        // set the thread id and assistant id to continue discussion with same context
                        setGptThreadId(response.threadid);
                        setGptAssistantId(response.assistantid);

                        // add response in ChatMessage array to display in view
                        let responseMessageObj = { ...chatMessageObj };

                        let lastResponseMsg = '';
                        if(response.data && response.data.length > 0){
                        lastResponseMsg = response.data[0];
                        }
                        responseMessageObj.message = lastResponseMsg ;
                        responseMessageObj.username = "AI Assistant";
                        responseMessageObj.moduleselected = response.moduleselected;

                        setChatMessages((prevResponses) => [
                        ...prevResponses,
                        responseMessageObj,
                        ]);

                        //once the discussion start, set the chatObjective = null 
                        // so that AI can continue discussion with the same assistant
                        setChatObjective(null);

                    }
                    } catch (e) {
                    console.log("error message -->", e.message);
                    }

        };



        const ref = useRef(null);

        const scrollToBottom = () => {
            const lastChildElement = ref.current?.lastElementChild;
            lastChildElement?.scrollIntoView({ behavior: "smooth" });
        };

        // reset all records
        const resetAll = () => {
            setQueryMessage("");
        };

        const closeMobileAIPanelHandler = () => {
            document.body.classList.toggle("chatmsg_open");
        };

        const restartDiscussion = () =>{
            setChatMessages([]);
            setChatObjective(null);
            setGptAssistantId(null);
            setGptThreadId(null);
        }

        // get all prompts of ecosystem
        const getEcosystemPrompts = async() =>{
            try {
            let requestUrl =
                url.API_BASE_URL +
                url.API_CHAT_GPT_PROMPTS +
                `?ecosystemslug=${ecosystemSlug}`;

            console.log('ecosystem slug', requestUrl);

            const response = await getData(requestUrl);

            console.log('gptPrompts', response);

            if (response.status) {
                setGptPrompts(response.data);
            }
            } catch (error) {
            console.log(error.message);
            }
        }

        // get all ecosystem prompts available
        useEffect(()=>{
            getEcosystemPrompts();
        }, [])

        // when select a prompt, initiate chat converstion based on that prompt
        useEffect(() => {
            if (chatObjective != null) {
            chatMessageHandler();
            }
        }, [chatObjective]);


        useEffect(() => {
            // Scroll to the bottom when new messages adds
            scrollToBottom();
        }, [chatMessages]);


    return (
        <>
        
        <div className="help_chat">
            <Link to="#" 
            className="chat_circle_btn" 
            data-bs-toggle="offcanvas" 
            data-bs-target="#chat_offcanvasBottom" 
            aria-controls="offcanvasBottom">
                <span className="material-symbols-outlined text-white">forum</span>
            </Link>
        </div>


        <div className="chat_ofcanvas">
            <div className="offcanvas offcanvas-bottom" tabindex="-1" id="chat_offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                <div className="offcanvas-header d-block p-0">
                    <div className="chatusr_info d-flex align-items-center justify-content-between p-3">
                        <div className="ai_logo">
                            <Link to="#" className="chat_usrOuter profile_linkBtn d-flex align-items-center gap-2">
                                <div className="chatting_user">
                                <img src={assetImages.Ai_Logo} alt="assistant" />
                                </div>
                                <div className="chat_usrtext">
                                    <h3 className="fs-lg fw-semibold text-black">Pavon AI</h3>
                                </div>
                            </Link>
                        </div>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                </div>


                <div className="offcanvas-body small p-0">
                    <div className="chating_mdlPnl  ai_chat_innr">


                        {/* Chat conversations */}
                        <div className="chatting_innr">

                            <div 
                                className="msg_outer p-4"
                                ref={ref}
                                //id="chatmessagecontent"
                                style={{ height: "40rem" }}
                                >

                                <div className="msg_row d-flex gap-3 group_usrmsg_row d-flex gap-3">
                                    <div className="chat_infoLft d-flex gap-3">
                                        <div className="msg_usr overflow-hidden rounded-circle">
                                            <img src={assetImages.Ai_Logo} alt="assistant" />
                                        </div>
                                    </div>
                                    <div className="chat_msgright">
                                        <div className="grp_usrname_time mb-2">
                                            <h5>
                                                <span className="grp_usrTime"></span>
                                            </h5>
                                        </div>
                                        <div className="msg_text">
                                            <h2>{t('Hello')}</h2>
                                            <p>{t('To continue, please choose a service below or just say hello')} 😊 </p>
                                        </div>
                                    </div>
                                </div>


                                {/* Chat conversations */}
                                {chatMessages.map((chatMessage, index) => {

                                    const messageText = chatMessage.message.split("\n");

                                        if (chatMessage.userid) {

                                            // user message
                                            return (
                                                    <div className="msg_row d-flex gap-3 own_msgRow grp_ownmsg" key={index}>
                                                            <div className="chat_msgright">
                                                                <div className="chat_info_timeOuter">
                                                                    <h5>
                                                                        {chatMessage.time}
                                                                    </h5>
                                                                </div>
                                                                <div className="own_msgArea">
                                                                    <p>{chatMessage.message}</p>
                                                                </div>
                                                                
                                                            </div>
                                                    </div>

                                                );

                                        } else {
                                            // ai message
                                            return (
                                                    <div className="msg_row d-flex gap-3 group_usrmsg_row d-flex gap-3" key={index}>
                                                        <div className="chat_infoLft d-flex gap-3">
                                                            <div className="msg_usr overflow-hidden rounded-circle">
                                                                <img src={assetImages.Ai_Logo} alt="assistant" />
                                                            </div>
                                                        </div>
                                                        <div className="chat_msgright">
                                                            <div className="grp_usrname_time mb-2">
                                                                <h5>
                                                                    <span className="grp_usrTime">{chatMessage.time}</span>
                                                                </h5>
                                                            </div>
                                                            <div className="msg_text">
                                                                <div className={` ${aiCommunicationError ? 'text-danger' : ''}`}>
                                                                    {messageText.map((paragraph, index) => (
                                                                        <p key={index}>{paragraph}</p>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                );
                                    }

                                })}        


                                {/* thinking  */}
                                {waitingResponse ? (
                                    <div className="msg_row d-flex gap-3 reply_msgRow">
                                        <div className="chat_infoLft d-flex gap-3">
                                            <div className="msg_usr overflow-hidden rounded-circle">
                                                <img src={assetImages.Ai_Logo} alt="assistant" />
                                            </div>
                                            <div className="chat_info_timeOuter">
                                                <div className="chat_dots">
                                                    <img src="/image/ai_typing.svg" alt="typing" />

                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                ) : (
                                ""
                                )}
                                
                            </div>                            
                        </div>



                        {/* Predefined prompts for help */}
                        <div className={`reply_popup_area ai_suggetions_area ${chatMessages.length > 0 ? 'd-none' : ''} `}>
                            <ul className="d-flex align-items-center gap-1 justify-content-end flex-wrap list_style_none">
                            
                                {gptPrompts.length > 0 ?

                                    gptPrompts.map((gptPrompt, index)=>{

                                        return (<li key={index}>
                                        <Link 
                                            to="#" 
                                            className="d-flex align-items-center"
                                            onClick={()=>{
                                                console.log('gptPrompt.slug', gptPrompt.slug);
                                                setChatObjective(gptPrompt.slug)
                                            }}
                                        >
                                            {t(gptPrompt.title)}
                                        </Link>
                                        </li>)


                                        
                                    })
                                
                                    :''}
                            
                            </ul>
                        </div>


                    </div>


                    

                    {/* Chat input area */}
                    <div className="chat_sendPnl gap-2 d-flex align-items-center">
                        <div className="emoji_iconArea ai_emojiouter">
                            <ul className="list_style_none">
                                <li>
                                <Link to="#"
                                onClick={()=>{
                                    restartDiscussion();
                                }}
                                >
                                    <span className="material-symbols-outlined">refresh</span>
                                </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="send_textbx position-relative w-100">
                            <form
                                onSubmit={chatMessageHandler}
                            >
                                <input
                                type="text"
                                autoComplete="off"
                                className="form-control"
                                placeholder={t("Enter your message")}
                                value={queryMessage}
                                onChange={(e) => setQueryMessage(e.target.value)}
                                id="chartboxinput"
                                autoFocus={true}
                                />
                                <button type="submit" className="chat_send_btn">
                                    <span className="material-symbols-outlined">send</span>
                                </button>
                            </form>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        
        </>
    )


}


export default AiChat;