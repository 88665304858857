import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { assetImages } from "Constants/Images";

const TheaterPrivileges = ({ setSourceName }) => {
  const { t } = useTranslation();
  return (
    <section
      className="discover_sec bg-cover"
      style={{ backgroundImage: `url(${assetImages.DiscoverBg})` }}
    >
      <div className="container">
        <div className="discover_outer">
          <div className="row">
            <div className="col-lg-6">
              <div className="page_hdng text-right mb-5">
                <h2 className="fw-extra-light mb-4 text-white">
                  {t("Discover a")} <br />{" "}
                  <span className="fw-bold d-inline-block">
                    {t("World of")}
                  </span>{" "}
                  <span className="fw-bold d-block text-white">
                    {t("Theatrical Privileges")}
                  </span>
                </h2>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#booking_modal"
                  className="btn btn-primary"
                  onClick={() => {
                    setSourceName("theater_privileges");
                  }}
                >
                  {t("Book your seats now")}
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="discover_paragrapgh">
                <p className="fs-md">
                  {t(
                    "At the Theater Members Club, we believe that the passion for theater deserves to be celebrated and shared. For this reason, we have created an exclusive space where true lovers of performing arts can enjoy a unique and privileged theatrical experience. With a monthly subscription, you'll have access to a wide range of benefits designed to enrich your passion for theater and make every visit unforgettable."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TheaterPrivileges;
