const CustomerSatisfactionCard = ({ item }) => {
  return (
    <div class={item.className}>
      <div class="customer_row light-gredient-bg p-4 rounded-8">
        <h3 class="mb-3 fs-xl fw-bold">{item.title}</h3>
        <ul class="list_style_none d-flex gap-2">
          <li class="d-flex gap-2 align-items-start">
            <span class="material-symbols-outlined icon-fill text-danger">
              {item.icon}
            </span>
            <p>{item.description}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CustomerSatisfactionCard;
