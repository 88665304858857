import React from "react";

const TopPlaySildItem = ({ slideItem }) => {
  return (
    <div className="plays_slide_item">
      <img src={slideItem.imageUrl} alt="" />
    </div>
  );
};

export default TopPlaySildItem;
